<template>
    <div>
        <van-sticky>
            <title-bar2 :title="universityName" />
        </van-sticky>
        <div class="content-box">
            <div class="item-box1">
                <div>
                    <img class="box1-img" :src="schoolImg">
                </div>
                <div class="box1-txt">
                    <div class="box1-txt1">{{ universityName }}</div>
                    <div>
                        <span class="box1-txt2">高等院校</span>
                        <span class="box1-txt2">{{ universityTeachType }}</span>
                    </div>
                </div>
            </div>
            <div class="item-box2">
                <div class="box2-line1">
                    <div class="line1-title">院校特色</div>
                    <div>
                        <span v-for="item in universityCharacteristics" class="line1-characteristic">
                            {{ item }}
                        </span>
                    </div>
                </div>
                <div class="box2-line2">
                    <div class="line2-between">
                        <div class="line2-title">院校位置</div>
                        <div class="line2-province">
                            <img src="@/assets/images/mt/position.png" class="province-img" />
                            <span class="province-txt">{{ universityProvince }}</span>
                        </div>
                    </div>
                    <div class="line2-describe">官方网址：{{ universityWebsite }}</div>
                    <div class="line2-describe">官方电话：{{ universityPhone }}</div>
                    <div class="line2-describe">电子邮箱：{{ universityEmail }}</div>
                </div>
            </div>
            <div class="item-box3">
                <div class="box3-between">
                    <h3 class="box3-title1">硕士专业</h3>
                    <el-select class="box3-select" size="small" v-model="majorLearnway" @change="onSelectChange">
                        <el-option label="全日制" value="全日制"></el-option>
                        <el-option label="非全日制" value="非全日制"></el-option>
                    </el-select>
                </div>
                <div v-for="college in collegeInfoList">
                    <div class="box3-title2">{{ college.CollegeName }}</div>
                    <div class="box3-cell" v-for="major in college.MajorInfos">
                        <van-cell is-link :title="`[${major.MajorCode}]${major.MajorName}`" @click="onMajorClick(college, major)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            universityCode: null,
            universityName: null,
            universityTeachType: null,
            universityProvince: null,
            universityWebsite: null,
            universityPhone: null,
            universityEmail: null,
            universityCharacteristic: null,
            universityName: null,
            collegeInfos: null,
            majorCode: null,
            majorLearnway: "全日制",
        };
    },
    computed: {
        schoolImg() {
            if (this.universityCode) {
                return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
            }
        },
        universityCharacteristics: function () {
            if (this.universityCharacteristic) {
                var arr = this.universityCharacteristic.split(' ');
                if (arr.length > 2) {
                    arr.splice(0, 2);
                }
                return arr;
            }
        },
        collegeInfoList() {
            if (this.collegeInfos) {
                return this.collegeInfos.filter(p => p.MajorInfos.length > 0);
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.universityCode = this.$route.params.universityCode;
            this.majorCode = this.$route.query.majorCode;
        },
        //获取大学信息
        getUniversityInfo() {
            this.$toast.loading({ message: "正在加载...", duration: 0, forbidClick: true });
            var model = {
                UniversityCode: this.universityCode,
                MajorCode: this.majorCode,
                MajorLearningWay: this.majorLearnway,
            };
            this.$axios
                .post(Config.school.details, model)
                .then((res) => {
                    this.universityName = res.data.Data.UniversityName;
                    this.universityProvince = res.data.Data.UniversityProvince;
                    this.universityWebsite = res.data.Data.UniversityWebsite;
                    this.universityPhone = res.data.Data.UniversityPhone;
                    this.universityEmail = res.data.Data.UniversityEmail;
                    this.universityCharacteristic = res.data.Data.UniversityCharacteristic;
                    this.universityTeachType = res.data.Data.UniversityTeachType;
                    this.collegeInfos =  res.data.Data.CollegeInfos;
                    this.$toast.clear();
                })
                .catch((error) => {
                    this.$toast.clear();
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击专业
        onMajorClick(college, major) {
            this.$router.push({
                name: "School2",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    collegeCode: college.CollegeCode,
                    majorCode: major.MajorCode,
                    majorLearnway: this.majorLearnway
                }
            });
        },
        //学习方式切换
        onSelectChange() {
            this.getUniversityInfo();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getUniversityInfo();
    }
};
</script>
<style lang="less" scoped>
.content-box {
    padding: 10px;
}

.item-box1 {
    display: flex;
    flex-direction: row;
    background-color: white;
    align-items: center;
    border-radius: 10px;
    padding: 10px 0;

    .box1-img {
        width: 80px;
        height: 80px;
        margin: 10px;
    }

    .box1-txt {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .box1-txt1 {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .box1-txt2 {
            color: white;
            font-size: 14px;
            border-radius: 15px;
            background-color: #FE6601;
            padding: 5px 10px;
            margin-right: 10px;
        }
    }

}

.item-box2 {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;

    .box2-line1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;

        .line1-title {
            width: 80px;
            font-size: 15px;
        }

        .line1-characteristic {
            color: gray;
            background-color: #F6F6F6;
            border-radius: 5px;
            margin-right: 5px;
            padding: 2px 5px;
            font-size: 12px;
        }
    }

    .box2-line2 {
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        .line2-between {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .line2-title {
                font-size: 15px;
            }

            .line2-province {
                display: flex;
                justify-content: center;
                align-items: center;

                .province-txt {
                    color: #888;
                    font-size: 13px;
                }

                .province-img {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                }
            }
        }

        .line2-describe {
            color: gray;
            font-size: 13px;
            margin-top: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.item-box3 {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;

    .box3-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .box3-select {
        width: 120px;
    }

    .box3-title1 {
        font-size: 17px;
        margin: 15px 0;
    }

    .box3-title2 {
        font-size: 15px;
        font-weight: 700;
        margin: 10px 0;
    }

    .box3-title2::before {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        display: inline-block;
        vertical-align: middle;
        background-color: #FE5E03;
        content: "";
        margin-right: 10px;
    }
}
</style>