<template>
    <div>
        <div>
            <school-banner :backgroundImg="proxyBannerImg" :schoolImg="schoolImg" :contactImg="proxyContactImg" :name="universityName" :province="universityProvince" :characteristic="universityCharacteristic" :website="universityWebsite" :phone="universityPhone" :email="universityEmail"></school-banner>
        </div>
        <div class="content-box" v-loading="loading" element-loading-text="加载中...">
            <div class="content-left">
                <div class="content-between">
                    <header-entry>硕士专业</header-entry>
                    <el-select size="small" v-model="majorLearnway" @change="onSelectChange">
                        <el-option label="全日制" value="全日制"></el-option>
                        <el-option label="非全日制" value="非全日制"></el-option>
                    </el-select>
                </div>
                <div class="content-list">
                    <div v-for="(college, index1) in collegeInfoList" class="college-item" :key="index1">
                        <div class="college-name">{{ college.CollegeName }}</div>
                        <ul class="major-box">
                            <li v-for="(major, index2) in college.MajorInfos" class="major-item" :key="index2">
                                <span class="major-name" @click="onMajorClick(college, major)">
                                    {{ `[${major.MajorCode}]${major.MajorName}` }}
                                </span>
                            </li>
                        </ul>
                        <div class="college-line" v-if="index1 < collegeInfoList.length - 1"></div>
                    </div>
                </div>
            </div>
            <div class="content-right">
                <school-hot></school-hot>
            </div>
        </div>
    </div>
</template>
    
<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolBanner from "@/components/PC/SchoolBanner";
import SchoolHot from "@/components/PC/SchoolHot";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        HeaderEntry,
        SchoolBanner,
        SchoolHot,
    },
    data() {
        return {
            loading: true,
            universityCode: null,
            universityName: null,
            universityProvince: null,
            universityWebsite: null,
            universityPhone: null,
            universityEmail: null,
            universityCharacteristic: null,
            collegeInfos: null,
            majorCode: null,
            majorLearnway: "全日制",
        };
    },
    computed: {
        schoolImg() {
            if (this.universityCode) {
                return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
            }
        },
        proxyBannerImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=6`;
        },
        proxyContactImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
        },
        collegeInfoList() {
            if (this.collegeInfos) {
                return this.collegeInfos.filter(p => p.MajorInfos.length > 0);
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.universityCode = this.$route.params.universityCode;
            this.majorCode = this.$route.query.majorCode;
        },
        //获取大学信息
        getUniversityInfo() {
            this.loading = true;
            var model = {
                UniversityCode: this.universityCode,
                MajorCode: this.majorCode,
                MajorLearningWay: this.majorLearnway,
            };
            this.$axios
                .post(Config.school.details, model)
                .then((res) => {
                    this.universityName = res.data.Data.UniversityName;
                    this.universityProvince = res.data.Data.UniversityProvince;
                    this.universityWebsite = res.data.Data.UniversityWebsite;
                    this.universityPhone = res.data.Data.UniversityPhone;
                    this.universityEmail = res.data.Data.UniversityEmail;
                    this.universityCharacteristic = res.data.Data.UniversityCharacteristic;
                    this.collegeInfos =  res.data.Data.CollegeInfos;
                    this.loading = false;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击专业
        onMajorClick(college, major) {
            this.$router.push({
                name: "School2",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    collegeCode: college.CollegeCode,
                    majorCode: major.MajorCode,
                    majorLearnway: this.majorLearnway
                }
            });
        },
        //学习方式切换
        onSelectChange() {
            this.getUniversityInfo();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getUniversityInfo();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.content-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-list {
    border: 1px solid #e6e6e6;
    margin-top: 20px;
}

.college-item {
    margin: 10px 20px;
}

.college-name {
    color: #555555;
    font-size: 16px;
    font-weight: 600;
}

.college-name::before {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    background-color: #FE5E03;
    content: "";
    margin-right: 10px;
}

.college-line {
    border-top: 1px solid #e6e6e6;
    margin: 20px 0px;
}

.major-box {
    width: 100%;
}

.major-item {
    display: inline-block;
    width: 50%;
    margin-top: 20px;
}

.major-name {
    cursor: pointer;
    font-size: 14px;
    color: #555555;
}

.major-name:hover {
    color: #FE5E03;
}
</style>